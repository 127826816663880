<template>
    <el-card>
        <div slot="header">
            <h2>添加活动</h2>
            <el-alert
                class="tip-top"
                title="活动发起前，至少需要提前1天进行审核"
                :closable="false"
                type="warning"
                show-icon>
            </el-alert>
        </div>
            
        <el-form 
            ref="addActivityRef"
            :rules="addActivityRules"
            :model="addActivityForm" 
            label-width="80px">
            <el-form-item label="活动名称" prop="name">
                <el-col :span="12">
                    <el-input v-model="addActivityForm.name"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="活动地点" prop="region">
                <el-col :span="12">
                    <el-input v-model="addActivityForm.region" @focus="getLocation"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="活动时间" prop="date">
                <el-col :span="24">
                    <el-date-picker
                        v-model="addActivityForm.date"
                        type="datetimerange"
                        align="left"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="timestamp"
                        @change="activityTime"
                        :default-time="['12:00:00', '08:00:00']">
                    </el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item label="活动性质">
                <el-checkbox-group v-model="addActivityForm.type">
                    <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                    <el-checkbox label="商超（开业/促销）活动" name="type"></el-checkbox>
                    <el-checkbox label="地推活动" name="type"></el-checkbox>
                    <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                    <el-checkbox label="品牌曝光" name="type"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="活动礼品">
                <el-switch v-model="addActivityForm.gift"></el-switch>
            </el-form-item>
            <el-form-item label="领取方式" v-if="addActivityForm.gift === true">
                <el-checkbox-group v-model="addActivityForm.code">
                    <el-checkbox label="二维码" name="code"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="礼品上限" v-if="addActivityForm.gift === true">
                <el-input-number v-model="addActivityForm.num" :min="10" label="描述文字"></el-input-number>
            </el-form-item>

            <el-form-item label="活动封面">
                <el-upload
                    ref="uploadImg"
                    :action="uploadURL"
                    list-type="picture-card"
                    :on-success="handleSuccess"
                    :on-preview="handlePreview"
                    :headers="headerObj"
                    :limit="1"
                    :on-exceed="exceedImg"
                    :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="活动简介">
                <el-input type="textarea" v-model="addActivityForm.desc"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="addActivity">立即创建</el-button>
            </el-form-item>
        </el-form>

        

        <!-- 地图 -->
        <el-dialog
            title="活动地点"
            :visible.sync="mapDialogVisible"
            width="50%">
            <bd-map class="getMap" :isBdmap="Bdmap" :key="timer" @address="getAddress" @closeMap="closeBdMap"></bd-map>
        </el-dialog>

        <!-- 图片预览 -->
        <el-dialog
            title="活动封面"
            :visible.sync="previewVisible"
            width="50%">
            <img :src="previewPath" alt="" class="previewImg">
        </el-dialog>
    </el-card>
</template>



<script>
import bdMap from '@/components/Map'
import { setToken, getToken, setUserData } from '@/utils/auth'

export default {
    data() {
        return {
            // 上传图片路径
            uploadURL: 'http://apiadmin.ytxmgy.com/admin/Index/upload',
            // 添加活动表单数据
            addActivityForm: {
                name: '',
                region: '',
                date: '',
                gift: false,
                code: [],
                num: '',
                type: [],
                desc: '',
                title: 'new',
                start_time: '20200629',
                address: '广州',
                uid: 1,
                // 图片
                pics: []
            },
            // 添加表单验证规则
            addActivityRules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 2, max: 20, message: '活动名称的长度在 2~20个字符之间', trigger: 'blur' }
                ],
                region: [
                    { required: true, message: '请选择活动地点', trigger: 'change' }
                ],
                date: [
                    { required: true, message: '请选择活动时间', trigger: 'blur' }
                ]
            },
            // 百度地图
            Bdmap: false,
            timer: '',
            mapDialogVisible: false,
            // 图片预览地址
            previewPath: '',
            previewVisible: false,
            headerObj: {
                apiAuth: getToken()
            },
        }
    },
    components: {
        bdMap
    },
    created() {
        // this.getLocation()
    },
    methods: {
        async addActivity() {
            console.log(this.addActivityForm);
            const {data: res} = await this.$http.post('Activity/store', this.addActivityForm )
            console.log(res)
        },
        // 活动时间
        activityTime() {
            console.log( this.addActivityForm.date )
        },
        // 获取定位
        getLocation() {
            // this.Bdmap.push({
            //     name: "bdMap"
            // });
            this.mapDialogVisible = true
            this.Bdmap = true
            // this.timer = new Date().getTime()
        },
        // 获取地址
        getAddress(data){
            this.addActivityForm.region = data
            this.mapDialogVisible = false
        },
        // 关闭地图
        closeBdMap(data) {
            console.log(data)
            this.mapDialogVisible = false
        },
        // 监听图片上传成功的事件
        handleSuccess(response, file, fileList) {
            if(response.code !== 1) {
                if (response.code === -14) {
                    setToken('')
                    setUserData('')
                    this.$router.push({ name: 'login' })
                }
                this.$refs.uploadImg.clearFiles()
                this.$message.error(response.msg)
                return
            }
            // console.log('response',response)
            // 1. 拼接得到图片信息对象
            const picInfo = {
                pic: response.data.fileUrl
            }
            // 2. 将图片信息对象 push 到 pics 数组中
            this.addActivityForm.pics.push(picInfo)
            // console.log(this.addActivityForm.pics)
        },
        // 处理图片预览效果
        handlePreview(file) {
            this.previewPath = file.response.data.fileUrl
            this.previewVisible = true
        },
        // 处理移除图片的 操作
        handleRemove(file) {
            // console.log(file)
            // 1. 获取将要删除的图片临时路径
            const filePath = file.response.data.fileUrl
            // 2. 从 pics 数组中，找到对应的索引值
            const i = this.addActivityForm.pics.findIndex(x => x.pic === filePath )
            // 3. 调用数组的 splice 方法， 把图片信息对象，从 pics 数组中移除 
            this.addActivityForm.pics.splice(i, 1)
        },
        // 超出图片
        exceedImg(files, fileList) {
            this.$message.warning('最多上传1张封面')
        }
    }
}
</script>

<style lang="less" scoped>
.getMap{
    min-height: 300px;
}
</style>